<template>
  <div class="w-full flex flex-col">

  <div
    class="w-full flex mb-3 -mt-3"
  >

  <div class="w-full">
    <img src="@/assets/dashboard-banner.png" style="height: 100%; width: 100%; object-fit: cover;" />
  </div>

    <div class="w-max absolute flex flex-col mt-8" style="z-index: 0">
      <div
        class="flex w-full flex-1p px-10"
      >
        <h1
          class="flex text-2xl font-bold mt-4 mr-3 text-white"
        >
          {{ greetings }}, {{ ` ${$AuthUser.fname}` }}
        </h1>

        <icon icon-name="sun-cloud" size="ms" class="mt-2" />
      </div>

     <!---- <div class="flex w-full my-2 px-11">
        <p class="text-base flex items-center text-white">Kindly complete your profile setting.</p>
      </div>

      <div class="flex w-full my-2 px-11">
        <progress-circle
        :completed-steps="5" :total-steps="10"
        :diameter="70"
        circle-color="#F7F7FF"
        start-color="#F15A29"
        stop-color="#F15A29"
        :animation-duration="2000"
        >
        <div class="rounded-full shadow-md flex items-center">
        <span class="text-xs leading-6 text-white">
          50%
        </span>
        </div>
      </progress-circle>
      <p class="flex text-xs text-white my-6 mx-2">Profile Completion</p>
      <div class="w-max h-10 my-3 mx-3 py-2 px-4 cursor-pointer bg-flame flex flex-col justify-center text-white rounded-lg">
        <p class="text-base text-white">Continue</p>
      </div>
      </div> -->
    </div>
  </div>


</div>
</template>

<script>
import { mapState } from "vuex";
import {
  formatDuration,
  intervalToDuration,
  differenceInYears
} from "date-fns";
// import { ProgressCircle } from 'vue-progress-circle'

export default {
    components: {
     // ProgressCircle
    },
  props: {
    appPage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      formatDuration,
      intervalToDuration,
      differenceInYears,
      chartDataObject: {
        datasets: [
          {
            backgroundColor: ["#F15A29", "#EEEEEE"]
          }
        ]
      },
      chartOptions: {
        legend: {
          display: true,
          position: "bottom"
        },
        responsive: true,
        maintainAspectRatio: false
      },
      myStyles: {
        height: `50px`,
        width: `50px`,
        fontSize: `20px`,
        position: "relative"
      },
      chartData: [100],
      greetings: ''
    };
  },
  computed: {
    ...mapState({
      employeeDetails: state => state.employeeDetails,
      loading: state => state.loading
    })
  },
  mounted() {
    this.chartDataObject.datasets[0].data = [...this.chartData];
    const todayDate = new Date();
    const getHrs = todayDate.getHours();

    if (getHrs < 12) {
      this.greetings = 'Good Morning'
    }
    else if (getHrs < 18) {
      this.greetings = 'Good Afternoon'
    }
    else {
      this.greetings = 'Good Evening'
    }
  }
};
</script>

<style></style>
